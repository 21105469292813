body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  padding:0px;
  margin:0px;
  font-family: 'Poppins', sans-serif;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-area {
	resize: none;
}

.account-pages{
  background-color: rgb(236, 236, 236);
  /* background-image: "url(./assets/images/profile-img.png)"  */
   /* background-image: url(./assets/images/kythertek-Letterhead-01.png); */
   /* background-repeat: no-repeat; */
   /* background-size: 100% 100vh; */
   /* object-fit: contain; */
   /* background-position: center;  */
}

.run-dev-container{
  animation: mymove .9s;
}
select{
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

Label{
  font-size: 13px;
  font-weight: 500;
}
.eye-password{
  position: absolute;
  right: 30px;
  color:#52606c;
}
.eye-password .fa{
  font-size: 15px;
}
.input-style {
  font-size:14px !important;;
  font-weight:400 !important;
}
.user-details{
  font-size: 13px;
  font-weight: 600;
}
.link{
  color: #571ae5;
  cursor: pointer;
}
.link:hover{
  color: #0d6efd;
}

.bg-purple{
  background-color: #571ae5;
}

.btn-purple{
  background-color: #571ae5 !important;
  border-color: #571ae5 !important;
  color: white!important;
  border:1px solid transparent;
}
.loading-size{
  height: 4rem!important;
  width: 4rem!important;
}
.btn-purple:hover{
  background-color: #7641f0 !important;
  border:1px solid #6837da !important;
  transition: all 0.9 ease-in-out;
}

.user-avatar{
  height:40px;
  width:40px;
}
.search-bar{
  width:250px!important;
}
.search-bar .form-outline .input{
  width:85%;
}
.search-bar span{
  width:15%;
}
.user-profile{
  cursor: pointer;
}
.user-profile-actions div:hover{
  background-color: #571ae5;
  color:white;
}

.float-item{
  height:60px;
  width:60px;
  background-color: #5f5f5f;
  cursor: pointer;
  z-index: 200;
}
.float-item .fa{
  font-size: 24px;
  color:#fff;
}
.float-item-active {
  background-color: #5217dd;
}
.anim{
  animation: mymove .9s;
}

.usvalues{
  top: -30px!important;
  right: -3px!important;
}
.fa-active{
  animation: rotate .9s;
}
.fa-unactive{
  animation: rotateRev .9s;
}


@keyframes rotate {
  from {
  transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);

  }
}

@keyframes rotateRev {
  from {
  transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);

  }
}
.icon-anim{
  animation: opacity-0-full 4s infinite;
}
@keyframes opacity-0-full {
  from {
  opacity: 0;
  -ms-transform: scaleY(0.7);
  transform: scale(0.7);
  }
  to {
    opacity: 100;
    -ms-transform: scaleY(1); 
    transform: scale(1);
  }
}
.right-side-dashboard{
  border-left:1px solid rgb(201, 201, 201);
}
.image-conatiner{
  height:120px;
  width:120px
}
.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.tiles{
  animation: mymove .9s;

}
.tiles:hover{
  box-shadow: 0 0 10px #969696;
  transition: all .3s ease-in;
  cursor: pointer;
}
.tile-title{
  font-size: 20px;
  font-weight:600;
}
.tile-name{
  color: #5f5f5f;
  text-transform: uppercase!important;
  font-size: 13px;
  font-weight: 500;
}
.alert-succes{
  background-color: #d6f3e9;
}
.alert-fail{
  background-color: #f3d6d6;
}
.error{
  font-size: 12px;
}
.fa{
  font-size: 20px;
  cursor: pointer;
}
.fa-bars{
  font-size: 18px;
}
.navbar-toggler::after {
  color: white;
  content: "Menu";
  font-size: 14px;
}
.top-navbar{
  z-index:100;
}
button{
  outline: none!important;
}
.modal-container{
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  z-index:102;
  background-color: rgba(0, 0, 0, 0.5)!important; 
  overflow-x: auto;
}
.active .side-bar-item{
  color: #fff!important;
  background-color: #571ae5;
}
.side-bar-item{
  width:40px;
  height:40px;
  background-color: #a8a8a8;
  transition: all .3s ease-in-out;
}
.side-bar-item:hover {
  background-color: #571ae5;
  
}

.table-border{
  border-bottom: 1px solid rgb(206, 204, 204);
}
.screen-divider{
  height:200px;
  min-height: 200px;
  background-image: linear-gradient(to right, #051937, #002362, #00298e, #1229b8, #5217dd);
}
.child-screen{
  margin-top: -165px;
}
.card-icon span{
  height:40px;
  width:40px;
  background-color: #5217dd;
  color: #ffff;
}

@keyframes mymove {
  from {opacity: 50%;
    -ms-transform: scaleY(0.7);
  transform: scale(0.7);
  }
  to {opacity: 100%;
    -ms-transform: scaleY(1); 
  transform: scale(1);
  }
}
.pointer{
  cursor: pointer;
}

.single-tile{
  width:22%;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

div a , .nav-item a {
  color: #ffff;
  text-decoration: none;
  transition: all 0.3s;
}
 div a:hover {
  color: #fff;
}


/* ------Top navbar STYLE---------------- */
.top-navbar{
  /* background: linear-gradient(90deg, rgba(82,23,221,1) 0%, rgba(9,9,121,1) 70%, rgba(4,0,69,1) 100%)!important; */
  background-image: linear-gradient(to right, #051937, #002362, #00298e, #1229b8, #5217dd)!important;
}
/* ------SIDEBAR STYLE---------------- */
.left-side{
  height:100vh;
  overflow: hidden;
  width: 70px;
  /* animation: move .9s; */
}

@keyframes move {
  from { margin-left: -253px;
  }
  to {
    margin-left: 0px;
  }
}

.sidebar {
  min-width: 70px;
  max-width: 70px;
  background: #363636;
  color: #fff;
  margin-left: -253px;
  transition: all 0.9s;
  height:100vh;
  overflow:hidden;
}

 .sidebar.is-open {
  margin-left: 0px;
  transition: all .9s;
}

.items-menu {
  color: #fff;
  background: #363636;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu {
  height: calc(100vh - 0px);
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: rgb(100, 100, 100);
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
}
.menu-title{
  color: rgb(184, 184, 184);
}

.rc-time-picker-input{
  border: none !important;
  padding:0px !important;
  outline: none!important;
  display: flex;
  align-items: center;
}
.rc-time-picker-clear {
  top: 9px!important;
}

/* ----CONTENT STYLE--------------- */
.content {
  margin-left: 0;
  height: 100vh;
  padding-left: 0px!important;
  padding-right: 0px!important;
  position: relative;
}

@media (max-width: 1199px) and (min-width: 1024px) { } 
@media (max-width: 1023px) and (min-width: 992px) { }
 @media (max-width: 991px) and (min-width: 768px) {
  .single-tile{
    width:100%;
  }
  } 
@media (max-width: 767px) and (min-width: 685px) {
  .single-tile{
    width:100%;
  }
 } 
@media (max-width: 684px) and (min-width: 568px) {
  .child-screen {
    margin-top: -124px;
}
  .content.is-open {
    margin-left: 0px;
    padding: relative;
  }
  .left-side{
    position: absolute;
    z-index: 100;
    /* animation: move .4s; */
    width: 0px;
  }
  .single-tile{
    width:100%;
  }

  .left-close {
    position: relative;
    width: 0px;
  }
  .top-navbar{
    position: fixed!important;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 70%;
  }
 } 
@media (max-width: 567px) and (min-width: 480px) { 
  .child-screen {
    margin-top: -124px;
}
  .content.is-open {
    margin-left: 0px;
    padding: relative;
  }
  .left-side{
    position: absolute;
    z-index: 100;
    /* animation: move .4s; */
    width: 0px;
  }
  .single-tile{
    width:100%;
  }

  .left-close {
    position: relative;
    width: 0px;
  }
  .top-navbar{
    position: fixed!important;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 70%;
  }
}
@media (max-width: 479px) and (min-width: 320px) {
  .child-screen {
    margin-top: -124px;
}
  .content.is-open {
    margin-left: 0px;
    padding: relative;
  }
  .left-side{
    position: absolute;
    z-index: 100;
    /* animation: move .4s; */
    width: 0px;
  }
  .single-tile{
    width:100%;
  }

  .left-close {
    position: relative;
    width: 0px;
  }
  .top-navbar{
    position: fixed!important;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 70%;
  }
 }