.weekDays-selector input {
    display: none!important;
  }
  
  .weekDays-selector input[type=checkbox] + label {
    display: inline-block;
    border-radius: 20px;
    background: #dddddd;
    height: 40px;
    width: 40px;
    margin-right: 8px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  
  .weekDays-selector input[type=checkbox]:checked + label {
    background: #5217dd;
    color: #ffffff;
  }